import cn from 'classnames';
import * as React from 'react';

import styles from './styles.module.css';

type TagsProps = {
  tags: string[];
  selectTag(tag: string): void;
  selectedTag: string;
};

const Tags: React.FC<TagsProps> = ({ tags, selectTag, selectedTag }) => {
  const handleClick = React.useCallback<React.MouseEventHandler<HTMLLIElement>>(
    e => {
      if (typeof e.currentTarget.dataset.tag === 'string') {
        selectTag(e.currentTarget.dataset.tag);
      }
    },
    [],
  );

  return (
    <ul className={styles.Tags}>
      {tags.map(tag => (
        <li
          key={tag}
          data-tag={tag}
          className={cn(styles.Tag, {
            [styles.SelectedTag]: selectedTag === tag,
          })}
          onClick={handleClick}
          role="button"
        >
          {tag}
        </li>
      ))}
    </ul>
  );
};

export default Tags;
