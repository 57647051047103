// Check if tag in storage exists

import { useEffect, useState } from 'react';

import { TAG } from '../../../constants';

export default function (posts: any[]): string[] {
  const [tags, setTags] = useState<string[]>([TAG.ALL]);

  useEffect(() => {
    const tagsByFrequency = {};
    const sortedTags: string[] = [];

    for (const post of posts) {
      // Exclude about page & dummy page
      if (post.node.fields.slug === '/about/') {
        continue;
      }

      if (post.node.frontmatter.tags === null) continue;

      for (const tag of post.node.frontmatter.tags) {
        if (tagsByFrequency[tag]) {
          tagsByFrequency[tag] = tagsByFrequency[tag] + 1; // update frequency
        } else {
          tagsByFrequency[tag] = 1;
          sortedTags.push(tag);
        }
      }
    }

    sortedTags.sort((a, b) => tagsByFrequency[b] - tagsByFrequency[a]);

    sortedTags.unshift(TAG.ALL);
    setTags(sortedTags);
  }, [posts]);

  return tags;
}
