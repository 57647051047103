import { useEffect, useState } from 'react';

import { TAG } from '../../../constants';

export default function (posts: unknown[], selectedTag: string) {
  const [filteredPosts, setFilteredPosts] = useState(posts);

  useEffect(() => {
    setFilteredPosts(
      posts.filter(
        ({ node }: any) =>
          selectedTag === TAG.ALL ||
          node.frontmatter.tags?.includes(selectedTag),
      ),
    );
  }, [posts, selectedTag]);

  return filteredPosts;
}
