import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import * as React from 'react';

import { PROFILE_IMAGE_NAME } from '../../../../consts';
import styles from './styles.module.css';

const Avatar: React.FC = () => {
  const data = useStaticQuery<{
    images: {
      edges: {
        node: {
          relativePath: string;
          name: string;
          childImageSharp: { fluid: FluidObject };
        };
      }[];
    };
  }>(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  // Loop through all files to find file that matches with image path
  const image = data.images.edges.find(n =>
    n.node.relativePath.includes(PROFILE_IMAGE_NAME),
  );

  if (!image) {
    return null;
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Inner} />
      <Img fluid={image.node.childImageSharp.fluid} />
    </div>
  );
};

export default Avatar;
