import * as React from 'react';

import PostCard from '../PostCard';

const PostList = ({ posts }) => {
  return (
    <section>
      {posts.map(post => (
        <PostCard
          key={post.node.id}
          title={post.node.frontmatter.title}
          date={post.node.frontmatter.date}
          path={post.node.fields.slug}
          excerpt={
            post.node.frontmatter.excerpt
              ? post.node.frontmatter.excerpt
              : post.node.excerpt
          }
          timeToRead={post.node.frontmatter.timeToRead ?? post.node.timeToRead}
          external={post.node.frontmatter.external}
        />
      ))}
    </section>
  );
};

export default PostList;
