import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../components/Layout';
import MainCard from '../components/MainCard';
import SEO from '../components/SEO';

const IndexPage: React.FC = ({ data }) => {
  const posts = data.allMdx.edges;

  return (
    <Layout isPostTemplate={false} showTitle={false}>
      <SEO title="Home" />
      <MainCard posts={posts} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } }
        fields: { slug: { ne: "/about/" } }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 180, truncate: true)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            tags
            excerpt
            draft
            external
            timeToRead
          }
        }
      }
    }
  }
`;

export default IndexPage;
