import * as React from 'react';

import { TAG } from '../../constants';
import Loader from '../Loader';
import PostList from '../Posts/PostList';
import Profile from '../Profile';
import Tags from '../Tags';
import useFilteredPosts from './hooks/useFilteredPosts';
import useFilteredTags from './hooks/useFilteredTags';
import styles from './styles.module.css';

const MainCard: React.FC<{ posts: any }> = ({ posts }) => {
  const [selectedTag, setSelectedTag] = React.useState(TAG.ALL);
  const filteredPosts = useFilteredPosts(posts, selectedTag);
  const tags = useFilteredTags(posts);

  const handleSelectTag = (tag: string): void => {
    setSelectedTag(tag);
  };

  return (
    <>
      <div className={styles.SubMain}>
        <Profile />
        {filteredPosts.length > 0 ? (
          <main className={styles.Layout}>
            <Tags
              selectedTag={selectedTag}
              selectTag={handleSelectTag}
              tags={tags}
            />
            <PostList posts={filteredPosts} />
          </main>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default MainCard;
