import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import * as React from 'react';

import { author, location, profileDescription } from '../../../customize';
import Avatar from './Avatar';
import styles from './styles.module.css';

const Profile: React.FC = () => {
  return (
    <div className={styles.Profile}>
      <Avatar />
      <Link to="/about" className={styles.AboutLink}>
        {author}
      </Link>
      <span className={styles.ProfileDescription}>{profileDescription}</span>
      <span className={styles.ProfileLocation}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        Currently in {location}
      </span>
    </div>
  );
};

export default Profile;
