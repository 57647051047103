import { faClock, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import * as React from 'react';

import { showTimeToRead } from '../../../../customize';
import styles from './styles.module.css';

type PostCardProps = {
  id: string;
  title: string;
  path: string;
  excerpt: string;
  timeToRead: number;
  external?: string;
};

const LinkWrapper: React.FC<{ external: boolean; href: string }> = ({
  external,
  href,
  children,
}) => {
  if (external) {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return <Link to={href}>{children}</Link>;
};

const extractHostname = (href: string): string => {
  const url = new URL(href);
  return url.hostname;
};

const PostCard: React.FC<PostCardProps> = ({
  id,
  title,
  path,
  excerpt,
  timeToRead,
  external,
}) => {
  return (
    <LinkWrapper href={external ?? path} external={!!external}>
      <div key={id} className={styles.Card}>
        <h3>{title}</h3>
        <span className={styles.Bar}>
          {showTimeToRead && (
            <span className={styles.TimeToRead}>
              <FontAwesomeIcon
                className={styles.Clock}
                icon={faClock}
                size="xs"
              />
              {timeToRead + 3} minute read
            </span>
          )}
          {external && (
            <span className={styles.ExternalSource}>
              <FontAwesomeIcon
                className={styles.ExternalLink}
                icon={faExternalLinkAlt}
                size="xs"
              />
              {extractHostname(external)}
            </span>
          )}
        </span>
        <p className={styles.Excerpt}>{excerpt}</p>
      </div>
    </LinkWrapper>
  );
};

export default PostCard;
